import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAuth } from "./AuthProvide"; // Adjust path as necessary
import Logo from "../assets/logo.jpg";

const VerifyEmail = () => {
  const { isRegistered } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const recommendedPlan = queryParams.get("recommended_plan");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isRegistered) {
      navigate("/", { replace: true });
    } else {
      setLoading(false);
    }
  }, [isRegistered, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-white">Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] p-4">
      <div className="flex justify-center mb-8">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="p-8 rounded-lg shadow-2xl w-full sm:w-1/2 border border-gray-600 text-center animate-fade-in">
        <h1 className="text-3xl font-bold mb-6 text-[#11AFC0] italic">
          Thank you for registering!
        </h1>
        <h2 className="text-lg text-white mb-4">
          Please check your email to verify your account.
        </h2>
        {recommendedPlan && (
          <p className="text-gray-200 mt-4">
            It looks like you have selected a recommended plan. Please review
            the details in your email.
          </p>
        )}
        <div className="mt-6">
          <button
            onClick={() => navigate("/login")}
            className="px-4 py-2 border text-white rounded-md bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
