import React from "react";
import Logo from "../assets/logo.jpg";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <div className="bg-gradient-to-br from-[#02273D] min-h-screen via-[#003B5C] to-[#001F3F] py-[4%] px-[1%]">
      <Link to="/">
        <img src={Logo} alt="logo" />
      </Link>
      <div className="max-w-full mx-auto p-6 rounded-lg shadow-lg my-[1%]">
        <h1 className="text-3xl font-bold mb-6 text-gray-300">
          Frequently Asked Questions
        </h1>

        <div className="space-y-6">
          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-200">
              What is FolioSynth?
            </h2>
            <p className="text-gray-100">
              FolioSynth is a comprehensive platform designed to streamline your
              financial portfolio management with cutting-edge tools and
              insights.
            </p>
          </div>

          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-200">
              How do I create an account?
            </h2>
            <p className="text-gray-100">
              To create an account, click on the "Sign Up" button on the
              homepage. Fill in the required details and follow the prompts to
              complete the registration process.
            </p>
          </div>

          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-200">
              I forgot my password. How can I reset it?
            </h2>
            <p className="text-gray-100">
              Click on the "Forgot Password" link on the login page. Follow the
              instructions to reset your password. You will receive an email
              with a link to create a new password.
            </p>
          </div>

          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-200">
              How can I contact customer support?
            </h2>
            <p className="text-gray-100">
              You can contact our customer support team by clicking on the
              "Contact Us" link at the bottom of the page or by emailing us at
              support@foliosynth.com.
            </p>
          </div>

          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-200">
              What should I do if I encounter a technical issue?
            </h2>
            <p className="text-gray-100">
              If you encounter any technical issues, please reach out to our
              support team using the "Contact Us" link. Provide as much detail
              as possible about the issue to help us resolve it quickly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
