import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Logo from "../assets/logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [tokenValid, setTokenValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract token from query string
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (!token) {
      setMessage("No token provided.");
      return;
    }

    // Validate the token when the component mounts
    const validateToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/reset-password?token=${token}`
        );
        setTokenValid(
          response.data.message ===
            "Token is valid. You can reset your password."
        );
      } catch (error) {
        setMessage("Invalid or expired token.");
      }
    };
    validateToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== repeatPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, {
        token,
        password,
        repeatPassword,
      });
      setMessage(
        "Password has been reset successfully. Redirecting to login page..."
      );
      setTimeout(() => navigate("/login"), 5000);
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          "System Maintenance in Progress. Please try again later."
      );
    }
    setLoading(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] flex items-center justify-center p-4 relative">
      <div className="w-full max-w-lg p-8 bg-[#02273D] rounded-lg shadow-lg relative">
        <div className="flex justify-center">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        {tokenValid ? (
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <h2 className="text-xl font-semibold mb-4 text-gray-200">
              Reset Password
            </h2>

            {/* New Password Input */}
            <div className="form-group flex flex-col w-full">
              <div className="flex items-center relative">
                <FontAwesomeIcon
                  icon={faLock}
                  className="absolute left-3 top-3 text-[#15AEC1]"
                />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="New password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full p-2 pl-10 border border-gray-300 bg-[#E8F0FE] rounded-lg mb-4"
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="absolute right-3 top-3 text-black cursor-pointer"
                  onClick={handleShowPassword}
                />
              </div>
            </div>

            {/* Confirm New Password Input */}
            <div className="form-group flex flex-col w-full">
              <div className="flex items-center relative">
                <FontAwesomeIcon
                  icon={faLock}
                  className="absolute left-3 top-3 text-[#15AEC1]"
                />
                <input
                  type={showRepeatPassword ? "text" : "password"}
                  placeholder="Confirm new password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatpassword(e.target.value)}
                  required
                  className="w-full p-2 pl-10 border border-gray-300 bg-[#E8F0FE] rounded-lg mb-4"
                />
                <FontAwesomeIcon
                  icon={showRepeatPassword ? faEyeSlash : faEye}
                  className="absolute right-3 top-3 text-black cursor-pointer"
                  onClick={handleShowRepeatPassword}
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-indigo-500 text-white py-2 rounded-lg hover:bg-indigo-700 transition duration-300"
            >
              {loading ? "Processing..." : "Submit"}
            </button>
            {message && (
              <p className="mt-4 text-center text-gray-200">{message}</p>
            )}
          </form>
        ) : (
          <p className="text-center text-gray-200">Invalid or expired token.</p>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
