import React, { useEffect } from "react";
import Logo from "../assets/logo.jpg";
import { useAuth } from "../utils/AuthProvide";
import { useNavigate, Link } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();

  useEffect(() => {
    if (!authenticatedUser) {
      navigate("/login");
    }
  }, [authenticatedUser, navigate]);

  return (
    <div className="bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] min-h-screen py-20">
      <div>
        <Link to="/landing">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="mt-[5%] mx-[2%]">
        <h1 className="text-[#14AFBB] text-6xl font-bold">
          Welcome to FolioSynth
        </h1>
        <h2 className="text-xl font-semibold text-gray-400 ml-2">
          Revolutionizing Value Streams and Value Delivery.
        </h2>
      </div>
    </div>
  );
};

export default Landing;
