import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../utils/AuthProvide";
import Logo from "../assets/logo.jpg";
import {
  faUser,
  faEnvelope,
  faLock,
  faQuestionCircle,
  faInfoCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../utils/Loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Register() {
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    title: "",
    telephone: "",
    email: "",
    contactmethod: "",
    help: "",
    purpose: "",
    password: "",
    repeatPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const { setIsRegistered } = useAuth();
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};

    if (!formData.username) newErrors.username = "Missing Username";
    if (!formData.name) newErrors.name = "Missing Name";
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.telephone) {
      newErrors.telephone =
        "Valid telephone number with country code is required";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Enter Valid Email";
    }
    if (!formData.contactmethod)
      newErrors.contactmethod = "Identify Contact Method";
    if (!formData.help) newErrors.help = " Please let us know how we can help!";
    if (!formData.purpose)
      newErrors.purpose = " Please share how you perceived value";
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.repeatPassword) {
      newErrors.repeatPassword = "Enter your password again";
    } else if (formData.password !== formData.repeatPassword) {
      newErrors.repeatPassword = "Passwords do not match";
    }
    if (!termsAccepted)
      newErrors.termsAccepted = "You must agree to the terms and conditions";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTelephoneChange = (value) => {
    setFormData({
      ...formData,
      telephone: value,
    });
  };
  const handleReset = () => {
    setFormData({
      username: "",
      name: "",
      title: "",
      telephone: "",
      email: "",
      contactmethod: "",
      help: "",
      purpose: "",
      password: "",
      repeatPassword: "",
    });
    setErrors({});
    setApiResponse("");
    setTermsAccepted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/register`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // If registration is successful
        setTimeout(() => {
          setLoading(false);
          setIsRegistered(true);
          setApiResponse(
            "Registration successful. Please check your email to verify your account. You will be redirected to login page"
          );
          setTimeout(() => {
            navigate("/login");
          }, 6000);
        }, 3000);
      } catch (error) {
        setLoading(false);

        if (error.response) {
          const errorMessage =
            error.response.data.message || "Registration failed.";

          if (errorMessage === "Email already exists") {
            setApiResponse(
              "The email you entered is already associated with an account. You will be redirected to the account recovery page."
            );

            setTimeout(() => {
              navigate("/account_recover");
            }, 6000);
          } else {
            setApiResponse(errorMessage);
          }
        } else {
          setApiResponse(
            "System Maintenance in Progress. Please try again later."
          );
        }
      }
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleCancel = () => {
    navigate("/login");
  };

  const handleTermsToggle = () => {
    setTermsAccepted(!termsAccepted);
  };

  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] py-10">
      <div className="flex justify-center items-center flex-grow">
        {loading && <Loader />}
        <div className="p-8 rounded-lg shadow-2xl w-full max-w-4xl bg-[#02273D] border border-gray-600">
          <div className="grid grid-cols-3 ">
            <div>
              <button
                type="button"
                className="text-white py-2 px-4 rounded"
                onClick={handleReset}
              >
                Reset Form
              </button>
            </div>
            <div className="">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="text-right">
              <button
                type="button"
                className="text-red-500 py-2 px-4 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
          <h1 className="text-3xl font-bold mb-6 text-white">Sign Up</h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              {/* Username Input */}
              <div className="form-group flex flex-col">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="absolute left-3 text-[#15AEC1]"
                  />
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Username"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.username ? "border-blue-300" : "border-gray-300"
                    } placeholder-white`}
                  />
                </div>
                {errors.username && (
                  <p className="text-red-500 text-xs mt-1">{errors.username}</p>
                )}
              </div>

              {/* Name Input */}
              <div className="form-group flex flex-col">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="absolute left-3 text-[#15AEC1]"
                  />
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.name ? "border-blue-300" : "border-gray-300"
                    } placeholder-white`}
                  />
                </div>
                {errors.name && (
                  <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                )}
              </div>

              {/* Title Input */}
              <div className="form-group flex flex-col">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="absolute left-3 text-[#15AEC1]"
                  />
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    placeholder="Title"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.title ? "border-blue-300" : "border-gray-300"
                    } placeholder-white`}
                  />
                </div>
                {errors.title && (
                  <p className="text-red-500 text-xs mt-1">{errors.title}</p>
                )}
              </div>

              {/* Telephone Input with react-phone-input-2 */}
              <div className="form-group flex flex-col">
                <div className="flex items-center relative">
                  <PhoneInput
                    country={"us"}
                    value={formData.telephone}
                    onChange={handleTelephoneChange}
                    placeholder="Telephone"
                    inputStyle={{
                      width: "100%",
                      paddingLeft: "50px",
                      backgroundColor: "#E8F0FE",
                      borderColor: errors.telephone ? "#4299E1" : "#364C5E",
                      color: "black",
                      height: 43,
                    }}
                    buttonStyle={{ height: 43 }}
                    dropdownStyle={{
                      borderColor: "#4299E1",
                      color: "black",
                    }}
                    containerStyle={{ width: "100%" }}
                  />
                </div>
                {errors.telephone && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.telephone}
                  </p>
                )}
              </div>

              {/* Email Input */}
              <div className="form-group flex flex-col">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="absolute left-3 text-[#15AEC1]"
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.email ? "border-blue-300" : "border-gray-300"
                    } placeholder-white`}
                  />
                </div>
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                )}
              </div>

              {/* Contact Method Input */}
              <div className="form-group flex flex-col">
                <div className="form-group flex flex-col">
                  <div className="flex items-center relative">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="absolute left-3 text-[#15AEC1]"
                    />
                    <select
                      name="contactmethod"
                      value={formData.contactmethod}
                      onChange={handleChange}
                      className={`w-full p-2 pl-10 border text-gray-600 border-gray-500 bg-[#E8F0FE] rounded ${
                        errors.contactmethod
                          ? "border-blue-300"
                          : "border-gray-300"
                      } placeholder-white`}
                    >
                      <option value="" disabled>
                        Select Contact Method
                      </option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                      <option value="both">Both</option>
                    </select>
                  </div>
                  {errors.contactmethod && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.contactmethod}
                    </p>
                  )}
                </div>

                {errors.contactmethod && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.contactmethod}
                  </p>
                )}
              </div>

              {/* How can we help? Input */}
              <div className="form-group col-span-2">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute left-3 top-3 text-[#15AEC1]"
                  />
                  <textarea
                    name="help"
                    value={formData.help}
                    onChange={handleChange}
                    placeholder="How can we help?"
                    rows="3"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.help ? "border-blue-300" : "border-gray-300"
                    } placeholder-white`}
                  />
                </div>
                {errors.help && (
                  <p className="text-red-500 text-xs mt-1">{errors.help}</p>
                )}
              </div>

              {/* How do you perceive value? Input */}
              <div className="form-group col-span-2">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="absolute left-3 top-3 text-[#15AEC1]"
                  />
                  <textarea
                    name="purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    placeholder="How do you perceive value?"
                    rows="3"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.purpose ? "border-blue-300" : "border-gray-300"
                    } placeholder-white`}
                  />
                </div>
                {errors.purpose && (
                  <p className="text-red-500 text-xs mt-1">{errors.purpose}</p>
                )}
              </div>

              {/* Password Input */}
              <div className="form-group flex flex-col">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faLock}
                    className="absolute left-3 text-[#15AEC1]"
                  />
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.password ? "border-blue-300" : "border-gray-300"
                    } placeholder-white`}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute right-3 text-black cursor-pointer"
                    onClick={handleShowPassword}
                  />
                </div>
                {errors.password && (
                  <p className="text-red-500 text-xs mt-1">{errors.password}</p>
                )}
              </div>

              {/* Repeat Password Input */}
              <div className="form-group flex flex-col">
                <div className="flex items-center relative">
                  <FontAwesomeIcon
                    icon={faLock}
                    className="absolute left-3 text-[#15AEC1]"
                  />
                  <input
                    type={showRepeatPassword ? "text" : "password"}
                    name="repeatPassword"
                    value={formData.repeatPassword}
                    onChange={handleChange}
                    placeholder="Repeat Password"
                    className={`w-full p-2 pl-10 border text-black border-gray-500 bg-[#E8F0FE] rounded ${
                      errors.repeatPassword
                        ? "border-blue-300"
                        : "border-gray-300"
                    } placeholder-white`}
                  />
                  <FontAwesomeIcon
                    icon={showRepeatPassword ? faEyeSlash : faEye}
                    className="absolute right-3 text-black cursor-pointer"
                    onClick={handleShowRepeatPassword}
                  />
                </div>
                {errors.repeatPassword && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.repeatPassword}
                  </p>
                )}
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="form-group flex items-center">
              <input
                type="checkbox"
                name="termsAccepted"
                checked={termsAccepted}
                onChange={handleTermsToggle}
                className="mr-2"
              />
              <label className="text-white">
                I agree to the{" "}
                <span
                  onClick={toggleTermsModal}
                  className="text-blue-300 cursor-pointer"
                >
                  terms and conditions
                </span>
              </label>
            </div>
            {errors.termsAccepted && (
              <p className="text-red-500 text-xs mt-1">
                {errors.termsAccepted}
              </p>
            )}
            <div>
              <button
                type="submit"
                className="bg-blue-500 w-full text-white py-2 px-4 rounded"
              >
                Sign Up
              </button>
            </div>
            {/* Submit Button */}
          </form>
          {apiResponse && (
            <div className="mt-4 p-2  text-white border border-red-400 rounded">
              {apiResponse}
            </div>
          )}
        </div>
      </div>

      {/* Terms and Conditions Modal */}
      {showTermsModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-200 p-4 rounded-lg max-w-[50%] mx-auto">
            <div className="flex justify-between">
              <div></div>
              <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
              <button
                className=" text-black text-4xl rounded"
                onClick={toggleTermsModal}
              >
                &times;
              </button>
            </div>

            <p className="mb-4">
              <strong>Terms of Service for FolioSynth LLC</strong> (including
              all domains owned by FolioSynth LLC)
              <br />
              <br />
              <strong>Acceptance of Terms</strong> By accessing and using
              FolioSynth LLC ("Website and/or Application"), you accept and
              agree to be bound by the terms and provision of this agreement.
              <br />
              <br />
              <strong>Provision of Services</strong> You agree and acknowledge
              that FolioSynth LLC is entitled to modify, improve or discontinue
              any of its services at its sole discretion and without notice to
              you.
              <br />
              <br />
              <strong>Proprietary Rights</strong> You acknowledge and agree that
              FolioSynth LLC may contain proprietary and confidential
              information including trademarks, service marks and patents
              protected by intellectual property laws and international
              intellectual property treaties.
              <br />
              <br />
              <strong>Submitted Content</strong> When you submit content to
              FolioSynth LLC you simultaneously grant an irrevocable, worldwide,
              royalty free license to publish, display, modify, distribute and
              syndicate your content worldwide.
              <br />
              <br />
              <strong>Termination of Agreement</strong> The Terms of this
              agreement will continue to apply in perpetuity until terminated by
              either party without notice at any time for any reason.
              <br />
              <br />
              <strong>Disclaimer of Warranties</strong> You understand and agree
              that your use of FolioSynth LLC is entirely at your own risk and
              our services are provided "As Is" and "As Available".
              <br />
              <br />
              <strong>Limitation of Liability</strong> You understand and agree
              that FolioSynth.com and any of its subsidiaries or affiliates
              shall in no event be liable for any direct, indirect, incidental,
              consequential, or exemplary damages.
              <br />
              <br />
              <strong>Governing Law</strong> This Terms of Service Agreement
              shall be governed by and construed in accordance with the laws of
              the state of Washington, USA.
              <br />
              <br />
              <strong>Changes to the Terms</strong> FolioSynth LLC reserves the
              right to modify these Terms from time to time at our sole
              discretion and without any notice. Date of Last Update: [Date]
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
