import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./components/utils/AuthProvide";
import Register from "./components/authentication/Register";
import Landing from "./components/landing/Landing";
import VerifyEmail from "./components/utils/VerifyEmail";
import Recovery from "./components/authentication/Recovery";
import ResetPassword from "./components/authentication/ResetPassword";
import Login from "./components/authentication/Login";
import EmailVerification from "./components/authentication/EmailVerify";
import AdminLogin from "./components/authentication/AdminLogin";
import AdminHome from "./components/admin/Dashboard";
import Page404 from "./components/utils/page404";
import Help from "./components/utils/Help";
import Header from "./components/utils/Header";
import AdminHeader from "./components/admin/AdminHeader";
import Footer from "./components/utils/Footer";
import "./App.css";

const App = () => {
  const { authenticatedUser, authenticatedAdmin } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/signup"
          element={
            <>
              <Header />
              <Register />
              <Footer />
            </>
          }
        />
        <Route
          path="/account_verification"
          element={
            <>
              <Header />
              <VerifyEmail />
              <Footer />
            </>
          }
        />
        <Route
          path="/login"
          element={
            authenticatedUser ? (
              <Navigate to="/landing" />
            ) : (
              <>
                <Header />
                <Login />
                <Footer />
              </>
            )
          }
        />
        <Route
          path="/"
          element={<Navigate to={authenticatedUser ? "/landing" : "/login"} />}
        />
        <Route
          path="/landing"
          element={
            authenticatedUser ? (
              <>
                <Header />
                <Landing />
                <Footer />
              </>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/home"
          element={
            authenticatedUser ? (
              <>
                <Header />
                <Landing />
                <Footer />
              </>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/help"
          element={
            <>
              <Header />
              <Help />
              <Footer />
            </>
          }
        />
        <Route
          path="/account_recover"
          element={
            <>
              <Header />
              <Recovery />
              <Footer />
            </>
          }
        />
        <Route
          path="/reset-password"
          element={
            <>
              <Header />
              <ResetPassword />
              <Footer />
            </>
          }
        />
        <Route
          path="/verify_email"
          element={
            <>
              <Header />
              <EmailVerification />
              <Footer />
            </>
          }
        />
        <Route
          path="/admin"
          element={
            authenticatedAdmin ? (
              <Navigate to="/dashboard" />
            ) : (
              <>
                <AdminHeader />
                <AdminLogin />
                <Footer />
              </>
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            authenticatedAdmin ? (
              <>
                <AdminHeader />
                <AdminHome />
                <Footer />
              </>
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
};

export default App;
