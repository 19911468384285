import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../assets/logo.jpg";

const Recovery = () => {
  const [stage, setStage] = useState("initial");
  const [formData, setFormData] = useState({ email: "", username: "" });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = (actionType) => {
    setStage(actionType);
    setMessage("");
    setFormData({ email: "", username: "" }); // Reset form data when changing stage
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let endpoint = "";
      let requestData = {};

      if (stage === "password") {
        endpoint = `${process.env.REACT_APP_API_URL}/forget-password`;
        requestData = { username: formData.username, email: formData.email };
      } else if (stage === "username") {
        endpoint = `${process.env.REACT_APP_API_URL}/forget-username`;
        requestData = { email: formData.email };
      }

      const response = await axios.post(endpoint, requestData);

      setMessage(
        response.data.message || "Check your email for further instructions."
      );
      setRedirect(true); // Set redirect to true when API call is successful
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          "System Maintenance in Progress. Please try again later."
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (redirect) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [redirect, navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#02273D] via-[#003B5C] to-[#001F3F] flex items-center justify-center p-4 relative">
      <div className="w-full max-w-md p-8 bg-[#02273D] rounded-lg shadow-lg relative">
        {stage !== "initial" && (
          <button
            onClick={() => setStage("initial")}
            className="absolute top-4 right-4 text-blue-500 hover:underline text-sm"
          >
            Back
          </button>
        )}
        {stage === "initial" && (
          <div className="flex flex-col items-center">
            <div className="absolute top-4 right-4">
              <div
                onClick={() => navigate("/login")}
                className="text-white hover:underline text-md cursor-pointer"
              >
                Login
              </div>
            </div>
            <div className="mb-6">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <h1 className="text-2xl font-bold mb-6 text-gray-300">
              Account Recovery
            </h1>
            <button
              onClick={() => handleButtonClick("password")}
              className="w-full bg-red-600 text-white py-2 rounded-lg mb-4 transition duration-300"
            >
              Forgot Password
            </button>
            <button
              onClick={() => handleButtonClick("username")}
              className="w-full bg-blue-500 text-white py-2 rounded-lg transition duration-300"
            >
              Forgot Username
            </button>
          </div>
        )}
        {(stage === "password" || stage === "username") && (
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <div className="mb-6">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <h2 className="text-xl font-semibold mb-4 text-gray-200">
              {stage === "password" ? "Recover Password" : "Recover Username"}
            </h2>
            {stage === "password" && (
              <input
                type="text"
                name="username"
                placeholder="Enter your username"
                value={formData.username}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              />
            )}
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
            />

            <button
              type="submit"
              className="w-full bg-indigo-500 text-white py-2 rounded-lg hover:bg-indigo-700 transition duration-300"
            >
              {loading ? "Processing..." : "Submit"}
            </button>
            {message && (
              <p className="mt-4 text-center text-gray-200">
                {message}
                {redirect && " Redirecting to login page..."}
              </p>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Recovery;
